import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import API from "../../helpers/api";
import FNSpinner from "../../components/FNSpinner";

const AddPayments = ({ refresh, close, id }) => {
  const [loading, setLoading] = useState(false);
  const [invoiceAmt, setInvoiceAmt] = useState(0);
  const [agency, setAgency] = useState();
  const [currency, setCurrency] = useState();
  const [deposit, setDeposit] = useState(0);
  const [balance, setBalance] = useState();
  const [payment, setPayment] = useState("");
  const [paymentMode, setModeOfPayment] = useState("");
  const [remarks, setRemarks] = useState("");
  const [bookingDate, setBookingDate] = useState("");

  const [reservation, setReservations] = useState();

  const reservationDetails = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/reservation/${id}`);
      setReservations(res?.data.reservation);
      setAgency(res?.data.reservation.agency);
      setCurrency(res?.data.reservation.currency);
      setDeposit(res?.data.reservation.deposit);
      setBalance(res?.data.reservation.balance);
      setInvoiceAmt(res?.data.reservation.totalamt);
      setBookingDate(res?.data.reservation.createdAt);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      payment,
      paymentMode,
      remarks,
      agency,
      deposit,
      balance,
      currency,
      invoiceAmt,
      bookingDate,
      reservationId: id,
    };

    try {
      const response = await API.post(`/payments`, data);
      setLoading(false);
      close();
      refresh();
      toast.success("Payment Added Successfully !!");
    } catch {
      setLoading(false);
      toast.error("Error while Adding Payment");
    }
  };

  useEffect(() => {
    reservationDetails();
  }, []);

  return (
    <div class="card">
      <div class="card-body">
        <div className="row">
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Booking Agency</label>
              <input
                type="text"
                class="form-control"
                autocomplete="off"
                value={agency}
                disabled
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Booking Date</label>
              <input
                type="text"
                class="form-control"
                autocomplete="off"
                value={reservation ? reservation.createdAt : ""}
                disabled
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Currency</label>
              <input
                type="text"
                class="form-control"
                autocomplete="off"
                value={currency}
                disabled
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Invoice Amount</label>
              <CurrencyFormat
                value={invoiceAmt}
                thousandSeparator={true}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Deposit Paid</label>
              <CurrencyFormat
                value={deposit}
                thousandSeparator={true}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-4">
            <div class="mb-3">
              <label class="form-label">Balance</label>
              <CurrencyFormat
                value={balance}
                thousandSeparator={true}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Enter Payment Amount</label>
              <CurrencyFormat
                value={payment}
                thousandSeparator={true}
                className="form-control"
                placeholder="Enter Deposit Amount"
                onValueChange={(values) => {
                  const { value } = values;
                  setPayment(parseFloat(value) || 0);
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Mode Of Payment</label>

              <select
                class="form-select"
                value={paymentMode}
                onChange={(e) => setModeOfPayment(e.target.value)}
              >
                <option selected="">Select Mode Of Payment</option>
                <option value="cash">Cash</option>
                <option value="mobileMoney">Mobile Money</option>
                <option value="bank">Bank</option>
              </select>
            </div>
          </div>
          <div className="col-12">
            <div class="mb-3">
              <label class="form-label">Payment Remarks</label>
              <textarea
                type="text"
                class="form-control"
                autocomplete="off"
                value={remarks}
                rows={3}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="div mt-3">
          <button class="btn btn-primary me-2" onClick={handleSubmit}>
            {loading ? <FNSpinner /> : "Add Payment"}
          </button>
          <button class="btn btn-outline-primary" onClick={close}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPayments;
