import { useState, useEffect } from "react";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { CSVLink } from "react-csv";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

const FNTable = ({
  data,
  viewDetails,
  invoiceDetails,
  handleEdit,
  handleDelete,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    bookingDateRange: "",
    agency: "",
    status: "",
    checkInDateRange: "",
    checkOutDateRange: "",
    currency: "",
  });

  useEffect(() => {
    const filtered = data.filter((item) => {
      const bookingDate = moment(item.createAt);
      const checkInDate = moment(item.datein);
      const checkOutDate = moment(item.dateout);

      return (
        (filters.bookingDateRange === "" ||
          isDateInRange(bookingDate, filters.bookingDateRange)) &&
        (filters.agency === "" ||
          item.agency.toLowerCase().includes(filters.agency.toLowerCase())) &&
        (filters.status === "" || item.status === filters.status) &&
        (filters.checkInDateRange === "" ||
          isDateInRange(checkInDate, filters.checkInDateRange)) &&
        (filters.checkOutDateRange === "" ||
          isDateInRange(checkOutDate, filters.checkOutDateRange)) &&
        (filters.currency === "" || item.currency === filters.currency)
      );
    });
    setFilteredData(filtered);
  }, [data, filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDateRangeChange = (name, start, end) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: `${start.format("YYYY-MM-DD")} to ${end.format("YYYY-MM-DD")}`,
    }));
  };

  const isDateInRange = (date, range) => {
    if (!range) return true;
    const [start, end] = range.split(" to ");
    return date.isBetween(start, end, null, "[]");
  };

  const csvData = filteredData.map((item) => ({
    "Booking Date": moment(item.createAt).format("YYYY-MM-DD"),
    "Tour and Travel Agency": item.agency,
    Status: item.status,
    "Check In Date": moment(item.datein).format("YYYY-MM-DD"),
    "Check Out Date": moment(item.dateout).format("YYYY-MM-DD"),
    Days: item.days,
    Currency: item.currency,
    Deposit: item.deposit,
    Balance: item.balance,
    "Total Amount": item.totalamt,
  }));

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-10">
            {" "}
            <div className="filters mb-4">
              <div className="row g-3">
                <div className="col-md-2">
                  <label className="form-label">Booking Date Range</label>
                  <DateRangePicker
                    initialSettings={{ autoUpdateInput: false }}
                    onApply={(event, picker) =>
                      handleDateRangeChange(
                        "bookingDateRange",
                        picker.startDate,
                        picker.endDate
                      )
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select booking date range"
                      value={filters.bookingDateRange}
                      readOnly
                    />
                  </DateRangePicker>
                </div>
                <div className="col-md-2">
                  <label className="form-label">Agency</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter by agency"
                    name="agency"
                    value={filters.agency}
                    onChange={handleFilterChange}
                  />
                </div>
                <div className="col-md-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select"
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                  >
                    <option value="">All Statuses</option>
                    <option value="comfirmed">Confirmed</option>
                    <option value="Tentative">Tentative</option>
                    <option value="waitingList">Waiting List</option>
                    <option value="canceled">Canceled</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label className="form-label">Check In Date Range</label>
                  <DateRangePicker
                    initialSettings={{ autoUpdateInput: false }}
                    onApply={(event, picker) =>
                      handleDateRangeChange(
                        "checkInDateRange",
                        picker.startDate,
                        picker.endDate
                      )
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select check-in date range"
                      value={filters.checkInDateRange}
                      readOnly
                    />
                  </DateRangePicker>
                </div>
                <div className="col-md-2">
                  <label className="form-label">Check Out Date Range</label>
                  <DateRangePicker
                    initialSettings={{ autoUpdateInput: false }}
                    onApply={(event, picker) =>
                      handleDateRangeChange(
                        "checkOutDateRange",
                        picker.startDate,
                        picker.endDate
                      )
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select check-out date range"
                      value={filters.checkOutDateRange}
                      readOnly
                    />
                  </DateRangePicker>
                </div>
                <div className="col-md-2">
                  <label className="form-label">Currency</label>
                  <select
                    className="form-select"
                    name="currency"
                    value={filters.currency}
                    onChange={handleFilterChange}
                  >
                    <option value="">All Currencies</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            {" "}
            <div className="d-flex justify-content-end align-items-center  h-100 ">
           
              <div>
                {/* <button
                  className="btn btn-outline-primary me-2"
                  onClick={() => setShowFilters(!showFilters)}
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                </button> */}
                <CSVLink
                  data={csvData}
                  filename={"reservations-data.csv"}
                  className="btn btn-warning"
                >
                  Export to Excel
                </CSVLink>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table align-middle table-striped table-sm">
            <thead className="table-dark">
              <tr>
                <th>Booking Date</th>
                <th>Tour and Travel Agency</th>
                <th>Status</th>
                <th>Check In Date</th>
                <th>Check Out Date</th>
                <th>Days</th>
                <th>Currency</th>
                <th>Deposit</th>
                <th>Balance</th>
                <th>Total Amount</th>
                <th>View Invoice</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr key={item.id}>
                  <td>{moment(item.createAt).format("YYYY-MM-DD")}</td>
                  <td>{item.agency}</td>
                  <td>
                    <span
                      className={`badge ${
                        item.status === "comfirmed"
                          ? "bg-success"
                          : item.status === "Tentative"
                          ? "bg-warning"
                          : item.status === "waitingList"
                          ? "bg-info"
                          : item.status === "canceled"
                          ? "bg-danger"
                          : "bg-light"
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td>{moment(item.checkin).format("YYYY-MM-DD")}</td>
                  <td>{moment(item.checkout).format("YYYY-MM-DD")}</td>
                  <td>{item.days}</td>
                  <td>{item.currency}</td>
                  <td>
                    <CurrencyFormat
                      value={item.deposit}
                      displayType="text"
                      thousandSeparator
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={item.balance}
                      displayType="text"
                      thousandSeparator
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={item.totalamt}
                      displayType="text"
                      thousandSeparator
                    />
                  </td>
                  <td>
                    <button
                      onClick={() => invoiceDetails(item.id)}
                      className="btn btn-sm btn-outline-warning"
                    >
                      Print Invoice
                    </button>
                  </td>
                  <td>
                    <div className="btn-group" role="group">
                      <button
                        onClick={() => viewDetails(item.id)}
                        className="btn btn-sm btn-outline-primary"
                      >
                        <i className="mdi mdi-eye"></i>
                      </button>
                      <button
                        onClick={() => handleEdit(item.id)}
                        className="btn btn-sm btn-outline-warning"
                      >
                        <i className="mdi mdi-pencil"></i>
                      </button>
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="btn btn-sm btn-outline-danger"
                      >
                        <i className="mdi mdi-trash-can"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FNTable;

// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React from "react";
// import moment from "moment";
// import CurrencyFormat from "react-currency-format";

// const FNTable = ({
//   data,
//   viewDetails,
//   invoiceDetails,
//   handleEdit,
//   handleDelete,
// }) => {
//   const truncateText = (text, maxLength) => {
//     if (text.length <= maxLength) {
//       return text;
//     }
//     return text.substring(0, maxLength) + "...";
//   };

//   return (
//     <div class="table-responsive">
//       <table class="table align-middle table-striped table-sm">
//         <thead className="table-dark">
//           <tr>
//             <th>Booking Date</th>
//             <th>Tour and Travel Agency</th>
//             <th>Status</th>
//             <th>Check In Date</th>
//             <th>Check Out Date</th>
//             <th>Days</th>
//             <th>Currency</th>
//             <th>Deposit</th>
//             <th>Balance</th>
//             <th>Total Amount</th>
//             <th>View Invoice</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data &&
//             data.map((item) => (
//               <tr key={item.id}>
//                 {/* <td>{truncateText(item.site, 20)}</td> */}
//                 <td>{moment(item.createAt).format("YYYY-MM-DD")}</td>
//                 <td>{item.agency}</td>
//                 <td>
//                   <span
//                     className={`badge ${
//                       item.status === "comfirmed"
//                         ? "bg-success"
//                         : item.status === "Tentative"
//                         ? "bg-warning"
//                         : item.status === "waitingList"
//                         ? "bg-info"
//                         : item.status === "canceled"
//                         ? "bg-danger"
//                         : "bg-light"
//                     }`}
//                   >
//                     {item.status}
//                   </span>
//                 </td>

//                 <td>{moment(item.datein).format("YYYY-MM-DD")}</td>
//                 <td>{moment(item.dateout).format("YYYY-MM-DD")}</td>
//                 <td>{item.days}</td>
//                 <td>{item.currency}</td>
//                 <td>
//                   <CurrencyFormat
//                     value={item.deposit}
//                     displayType="text"
//                     thousandSeparator
//                   />
//                 </td>
//                 <td>
//                   <CurrencyFormat
//                     value={item.balance}
//                     displayType="text"
//                     thousandSeparator
//                   />
//                 </td>
//                 <td>
//                   <CurrencyFormat
//                     value={item.totalamt}
//                     displayType="text"
//                     thousandSeparator
//                   />
//                 </td>
//                 {/* <td><div class="d-inline-block me-2" data-bs-toggle="tooltip" data-bs-placement="top">
//                                     <a onClick={() => viewDetails(item.id)} class="btn btn-success btn-sm">Generate Invoice</a>
//                                 </div></td> */}

//                 <td>
//                   <div
//                     class="d-inline-block me-2"
//                     data-bs-toggle="tooltip"
//                     data-bs-placement="top"
//                   >
//                     <a
//                       onClick={() => invoiceDetails(item.id)}
//                       class="btn btn-warning btn-sm"
//                     >
//                       Print Invoice
//                     </a>
//                   </div>
//                 </td>
//                 <td>
//                   <div class="d-inline-block me-2">
//                     <a
//                       onClick={() => viewDetails(item.id)}
//                       class="action-icon text-primary"
//                       style={{ cursor: "pointer" }}
//                     >
//                       <i class="mdi mdi-eye font-size-20"></i>
//                     </a>
//                   </div>
//                   <div class="d-inline-block me-2">
//                     <a
//                       onClick={() => handleEdit(item.id)}
//                       class="action-icon text-warning"
//                       style={{ cursor: "pointer" }}
//                     >
//                       <i class="mdi mdi-comment-edit-outline font-size-20"></i>
//                     </a>
//                   </div>
//                   <div class="d-inline-block me-2">
//                     <a
//                       onClick={() => handleDelete(item.id)}
//                       class="action-icon text-danger"
//                       style={{ cursor: "pointer" }}
//                     >
//                       <i class="mdi mdi-trash-can font-size-20"></i>
//                     </a>
//                   </div>
//                 </td>
//               </tr>
//             ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default FNTable;
