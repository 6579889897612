import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";
import { Table, Card, Button, Row, Col, Image } from "react-bootstrap";
import API from "../../helpers/api";
import logo from "./logo.jpg";

const InvoiceDetails = () => {
  const [rooms, setRooms] = useState([]);
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const componentRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [reservationRes, roomsRes] = await Promise.all([
          API.get(`/reservation/${id}`),
          API.get(`/reservation/rooms/${id}`),
        ]);
        setReservation(reservationRes.data.reservation);
        setRooms(roomsRes.data.rooms);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid mt-4">
      <Row className="mb-3">
        <Col>
          <Link to="/reservations" className="btn btn-outline-primary">
            <i className="bi bi-arrow-left me-2"></i>Back To Reservations
          </Link>
        </Col>
        <Col className="text-end">
          <ReactToPrint
            trigger={() => (
              <Button variant="primary">
                <i className="bi bi-printer me-2"></i>Print Invoice
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>

      <Card ref={componentRef} className="shadow-sm">
        <Card.Body className="p-4">
          <Row className="align-items-center justify-content-between mb-4">
            <Col md={6} xs={6} sm={6} lg={6} xl={6}>
              <Image
                src={logo || "/placeholder.svg"}
                alt="Company Logo"
                fluid
                style={{ maxHeight: "100px" }}
              />
            </Col>
            <Col md={6} xs={6} sm={6} lg={6} xl={6} className="text-end ">
              <h4 className="text-primary mb-3">Invoice</h4>
              <p className="mb-1">
                <strong>Date:</strong>{" "}
                {moment(reservation.createAt).format("MMMM D, YYYY")}
              </p>
              <p className="mb-1">
                <strong>Invoice No:</strong> {reservation.id}/2025
              </p>
              <p className="mb-1">
                <strong>Check In:</strong>{" "}
                {moment(reservation.checkin).format("MMMM D, YYYY")}
              </p>
              <p className="mb-1">
                <strong>Check Out:</strong>{" "}
                {moment(reservation.checkout).format("MMMM D, YYYY")}
              </p>
            </Col>
          </Row>

          <hr className="my-4" />

          <Row className="mb-4">
            <Col>
              <h5 className="text-secondary">Bill To:</h5>
              <h4>{reservation.agency}</h4>
              {/* Add more customer details here if needed */}
            </Col>
          </Row>

          <Table responsive bordered hover>
            <thead>
              <tr className="bg-light">
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  Room Type
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  Meal Plan
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  Rate Per Room
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  No of Rooms
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  No of Adults
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  Total Adults Charge
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  No of Children
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  Total Children Charge
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                >
                  No of Nights
                </th>
                <th
                  style={{
                    backgroundColor: "#D46846",
                    color: "white",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                  }}
                  className="text-end"
                >
                  Amount ({reservation.currency})
                </th>
              </tr>
            </thead>
            <tbody>
              {rooms.map((item) => (
                <tr key={item.id}>
                  <td>{item.roomType}</td>
                  <td>{item.mealplan}</td>
                  <td>{item.roomRate}</td>
                  <td>{item.roomNo}</td>
                  <td>{item.adults}</td>
                  <td>{item.adultAmount}</td>
                  <td>{item.children}</td>
                  <td>{item.childrenAmount}</td>
                  <td>{item.roomNight}</td>
                  <td className="text-end">
                    <CurrencyFormat
                      value={item.roomTotal}
                      displayType="text"
                      thousandSeparator={true}
                      prefix={reservation.currency + " "}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="bg-light">
                <td colSpan="6" className="text-end">
                  <strong>Total Amount</strong>
                </td>
                <td className="text-end">
                  <h5 className="m-0 text-primary">
                    <CurrencyFormat
                      value={reservation.totalamt}
                      displayType="text"
                      thousandSeparator={true}
                      prefix={reservation.currency + " "}
                    />
                  </h5>
                </td>
              </tr>
            </tfoot>
          </Table>

          <Card.Footer className="mt-4 bg-light border-0">
            <h5 className="text-secondary mb-3">Payment Terms:</h5>
            <ul className="list-unstyled">
              <li>
                <i className="bi bi-check2 text-primary me-2"></i>A
                non-refundable 20% deposit of the booking value is required to
                confirm the booking
              </li>
              <li>
                <i className="bi bi-check2 text-primary me-2"></i>Bank transfer
                to Aadvark Tours and Safari Lodge Ltd
              </li>
              <li>
                <i className="bi bi-check2 text-primary me-2"></i>Bank of Africa
                Uganda Ltd. - Kampala - P.O. Box 2971 SWIFT CODE: AFRIUGKA XXX
              </li>
              <li>
                <i className="bi bi-check2 text-primary me-2"></i>Account:
                04262070016 (USD) OR 04262070003 (UGX)
              </li>
            </ul>
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InvoiceDetails;

// import React, { Fragment, useState, useEffect, useRef } from "react";
// import moment from 'moment';
// import { Link } from 'react-router-dom';
// import ReactToPrint from "react-to-print";
// import CurrencyFormat from 'react-currency-format';
// import API from "../../helpers/api";
// import logo from './logo.jpg'

// const InvoiceDetails = ({ match }) => {

//     const [rooms, setRooms] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [reservation, setReservations] = useState();

//     const { id } = match.params;

//     const componentRef = useRef();

//     const reservationDetails = async () => {
//         setLoading(true);
//         try {
//             const res = await API.get(`/reservation/${id}`);
//             setReservations(res?.data.reservation);
//             setLoading(false);
//         } catch (error) {
//             console.log("error", error);
//             setLoading(false);
//         }
//     };

//     const loadRooms = async () => {
//         setLoading(true);
//         try {
//             const res = await API.get(`/reservation/rooms/${id}`);
//             setRooms(res?.data.rooms);
//             setLoading(false);
//         } catch (error) {
//             console.log("error", error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         loadRooms();
//         reservationDetails();
//     }, []);
//     return (
//         <Fragment>
//             <div className="row">
//                 <div className="col-12">
//                     <div className="d-sm-flex align-items-center justify-content-between mb-3">
//                         <ol className="breadcrumb m-0">
//                             <li className="breadcrumb-item"><Link to="/reservations">Back To Reservations</Link></li>
//                         </ol>
//                         <div className="page-title-right">
//                             <ReactToPrint
//                                 trigger={() => <button class="btn btn-warning w-md waves-effect waves-light">Print Invoice</button>}
//                                 content={() => componentRef.current}
//                             />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="card" ref={componentRef}>
//                 <div className="card-body">
//                     <div className="row d-flex align-items-center">
//                         <div className="col-6">
//                             <div style={{ width: '100%', height: '20vh', overflow: 'hidden' }}>
//                                 <img src={logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
//                             </div>
//                         </div>
//                         <div className="col-6 justify-content-end">
//                             <h5>
//                                 <span>Invoice Date: {reservation ? moment(reservation.createAt).format('YYYY-MM-DD') : ""}</span>
//                                 <span className="mx-3">Invoice No: {reservation ? reservation.id : ""} / 2024</span>
//                             </h5>
//                             <h5>
//                                 <span>Check In: {reservation ? moment(reservation.checkin).format('YYYY-MM-DD') : ""}</span>
//                                 <span className="mx-3">Check Out: {reservation ? moment(reservation.checkout).format('YYYY-MM-DD'): ""}</span>
//                             </h5>
//                         </div>
//                     </div>
//                     <div className="row d-flex" >
//                         <div className="col-12" >
//                             <section className='d-flex flex-row justify-content-between'>
//                                 <div>
//                                     <h4> M/S : {reservation ? reservation.agency : ''}</h4>
//                                     {/* <h5>  Address: {supply.customer ? supply.customer.address : ''}</h5> */}
//                                 </div>

//                             </section>
//                             <section className="mt-4">
//                                 {/* <div class="py-2 mt-3">
//                                     <h3 class="font-size-15 fw-bold">Job Card Items Worked On</h3>
//                                 </div> */}
//                                 <div class="table-responsive">
//                                     <table class="table table-striped table-bordered border-secondary table-sm">
//                                         <thead className="table-dark">
//                                             <tr>
//                                                 <th>Room Type</th>
//                                                 <th>Meal Plan</th>
//                                                 <th>Rate Per room</th>
//                                                 <th>No of Rooms</th>
//                                                 <th>No of People</th>
//                                                 <th>No of Nights</th>
//                                                 <th class="text-end">{`Amount (${reservation ? reservation.currency : ""})`}</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {rooms &&
//                                                 rooms.map((item) => (
//                                                     <tr key={item.id}>
//                                                         <td>{item.roomType}</td>
//                                                         <td>{item.mealplan}</td>
//                                                         <td>{item.roomRate}</td>
//                                                         <td>{item.roomNo}</td>
//                                                         <td>{item.roomPeople}</td>
//                                                         <td>{item.roomNight}</td>
//                                                         <td class="text-end">
//                                                             <CurrencyFormat
//                                                                 value={item.roomTotal}
//                                                                 displayType="text"
//                                                                 thousandSeparator
//                                                             />
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             <tr>
//                                                 <td colspan="6" class="text-end">
//                                                     <strong>{`Total Amount (${reservation ? reservation.currency : ""})`}</strong></td>
//                                                 <td class="text-end"><h5 class="m-0">
//                                                     <CurrencyFormat
//                                                         value={reservation ? reservation.totalamt : ""}
//                                                         displayType="text"
//                                                         thousandSeparator
//                                                     />
//                                                 </h5></td>
//                                             </tr>
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </section>
//                             <footer>
//                                 <ul className='list-unstyled'>
//                                     <li>Payment terms: A non-refundable 20% deposit of the booking value is required to confirm the booking</li>
//                                     <li>Bank transfer to Aadvark Tours and Safari Lodge Ltd</li>
//                                     <li>Bank of Africa Uganda Ltd. - Kampala - P.O. Box 2971 SWIFT CODE: AFRIUGKA XXX</li>
//                                     <li>Account: 04262070016 (USD) OR 04262070003 (UGX)</li>
//                                 </ul>
//                             </footer>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </Fragment>
//     )
// }

// export default InvoiceDetails
